<template>
  <div class="BuyerDialog">
    <el-dialog
      :center="true"
      @close="tableVisible = false"
      :visible="tableVisible"
      :title="title"
     width="90%"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <el-button @click="onHandleBuyer" :disabled="!supplier_ids.length"
          >数据迁移</el-button
        >
        <div style="display: flex; align-items: center">
          <div class="inputs mr10">
            <el-input
              v-model="formChildData.search_supplier_name"
              placeholder="请输入供应商名称"
              clearable
            ></el-input>
          </div>
          <el-button
            @click="searchSupplier"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
        </div>
      </div>

      <div class="row-box d-flex flex-column mt-20 height-500">
        <el-table
          :data="listData"
          height="100%"
          :border="true"
          :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
          ref="multipleTable"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            type="index"
            width="100px"
            align="center"
            label="序号"
          >
          </el-table-column>
          <el-table-column prop="supplierTitle" align="center" label="供货商名称">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.supplierTitle) }}
            </template>
          </el-table-column>
          <el-table-column prop="stall" align="center" label="档口位置">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.stall) }}
            </template>
          </el-table-column>
          <el-table-column prop="supplierFullname" align="center" label="联系人">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.supplierFullname) }}
            </template>
          </el-table-column>
          <el-table-column prop="supplierMobile" align="center" label="联系电话">
            <template slot-scope="scope">
              {{ $empty.empty(scope.row.supplierMobile) }}
            </template>
          </el-table-column>
          <el-table-column prop="name" align="center" label="对接业务采购">
            <template slot-scope="scope" >
              {{ $empty.empty(scope.row.buyerName) }}
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="onHandleBuyer(scope.row)"
                >转移绑定</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="pagination-position">
          <el-pagination
            type="primary"
            background
            @size-change="handleBuyerChange"
            @current-change="handleBuyerCurrentChange"
            :current-page="currentlistPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="formChildData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="listtotal"
          >
          </el-pagination>
        </div>
      </div>
      <template #footer>
        <el-button @click="tableVisible = false">返回</el-button>
      </template>
    </el-dialog>

    <el-dialog
      title="转移绑定"
      :visible.sync="buyerDialogVisible"
      v-if="buyerDialogVisible"
      width="40%"
      class="dialog"
      center
    >
      <div class="content">
        <el-select
          v-model="buyer_id_new"
          placeholder="请选择数据将要迁移到的采购员姓名"
          filterable 
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.fullname + '(' + item.mobile + ')'"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div style="color: red;margin-top: 20px;">只转移供应商的绑定关系，用于维护供应商的基本信息，不包括商品迁移；只有相同类型的采购才可以迁移；</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="buyerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onHandleChose">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "BuyerDialog",
  props: {},
  data() {
    return {
      tableVisible: false,
      title: "",
      supplier_ids: [],
      buyerDialogVisible: false,
      formChildData: {
        buyer_id: "",
        search_supplier_name: "",
        page: 1,
        pageSize: 10,
      }, // 新增采购表单
      listData: [],
      currentlistPage: 1,
      listtotal: 0,
      buyer_id: "",
      buyer_id_new: "",
      options: [],
    };
  },
  computed: {},
  methods: {
    hqinfo(e) {
      this.title = "绑定供货商";
      this.listData = [];
      this.supplier_ids = [];
      console.log('查看选中是否有数据',this.supplier_ids);


      Object.assign(this.$data.formChildData, this.$options.data().formChildData);
      this.formChildData.buyer_id = this.buyer_id = e.id;
      // this.buyer_id_new=e.id//默认不显示
      // 重置分页参数
      this.formChildData.page = 1;
      this.formChildData.pageSize = 10;
      this.buyerSupplierList();
      this.tableVisible = true;
    },
    /**
     * 数据迁移确认
     */
    onHandleChose() {
      // console.log(this.buyer_id, this.supplier_ids);
      if (this.buyer_id_new) {
        let request_data=[]
        this.supplier_ids.forEach(el => {
          request_data.push({
            supplier_id: el,
            buyer_id: this.buyer_id,
            new_buyer_id: this.buyer_id_new,
          });
        });

        this.tool.debounce(() => {
          this.$api.general
            .movebindsupplier({request_data:request_data})
            .then((res) => {
              this.$message({
                type: "success",
                message: "迁移成功",
              });
              this.buyerDialogVisible = false;
              // this.tableVisible = false;
              this.buyerSupplierList();
              this.$emit("successDialog", true);
            });
        });
      } else {
        this.$message({
          type: "error",
          message: "请选择采购员",
        });
      }
    },
    /**
     * 数据迁移勾选成员
     */
    handleSelectionChange(val) {
      // this.multipleSelection = val;
      this.supplier_ids = [];
      console.log(val);
      val.forEach((item) => {
        this.supplier_ids.push(item.supplierId);
      });
    },
    /**
     * 触发数据迁移按钮
     */
    onHandleBuyer(row) {
      this.buyerDialogVisible = true;
      this.getAjaxBuyerList();
      if (row?.supplierId) {
        this.supplier_ids = [];
        this.supplier_ids.push(row.supplierId);
      }
    },
    /**
     * 获取采购人员列表
     */
    async getAjaxBuyerList() {
      const params = {
        buyer_id: this.buyer_id,
        type: 1, //1: 绑定供应商 2: 发品供应商
      };
      try {
        const { data } = await this.$api.general.movebuyerlist(params);
        this.options = data;
        this.buyer_id_new =data.length>0? data[0].id:'';
      } catch (err) {
        console.log("ajax getAjaxBuyerList err", err);
      }
    },
    buyerSupplierList() {
      this.$api.general.bindsupplierList(this.formChildData).then((res) => {
        console.log(res, "获取数据");
        this.listData = res.data.data;
        this.listtotal = res.data.total;
      });
    },
    searchSupplier() {
      this.formChildData.page = 1;
      this.currentlistPage = 1;
      this.buyerSupplierList();
    },
    handleBuyerChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.formChildData.pageSize = val;
      this.buyerSupplierList();
    },
    handleBuyerCurrentChange(val) {
      this.formChildData.page = val;
      this.currentlistPage = val;
      this.buyerSupplierList();
    },
  },
};
</script>

<style lang="scss" scoped>
.BuyerDialog {
  .height-500 {
    height: 800px;
  }
 /deep/ .el-dialog {
    margin-top: 0vh !important;
  }
  .pagination-position {
    margin-top: 20px;
  }
}
</style>
