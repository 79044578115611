var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "buyer bj" },
    [
      _c("div", { staticClass: "title" }, [
        _c(
          "div",
          { staticClass: "form jn-form-style" },
          [
            _c(
              "div",
              { staticClass: "form-item btn-wrap" },
              [
                _c("el-button", {
                  staticClass: "refresh",
                  staticStyle: { "margin-left": "5px" },
                  attrs: { type: "primary", icon: "el-icon-refresh" },
                  on: { click: _vm.onHandleRefresh },
                }),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.onHandleCreate },
                  },
                  [_vm._v("新 增")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-item" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "选择采购类型" },
                    model: {
                      value: _vm.formData.buyer_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "buyer_type", $$v)
                      },
                      expression: "formData.buyer_type",
                    },
                  },
                  _vm._l(_vm.buyWayList, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-item" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "选择集配中心", clearable: "" },
                    on: { change: _vm.onChangeLogistic },
                    model: {
                      value: _vm.formData.logistic_business_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "logistic_business_id", $$v)
                      },
                      expression: "formData.logistic_business_id",
                    },
                  },
                  _vm._l(_vm.logisticsList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-item" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入采购员名称", clearable: "" },
                  model: {
                    value: _vm.formData.fullname,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "fullname", $$v)
                    },
                    expression: "formData.fullname",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-item" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入所属小组", clearable: "" },
                  model: {
                    value: _vm.formData.team_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "team_name", $$v)
                    },
                    expression: "formData.team_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-item" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入联系电话", clearable: "" },
                  model: {
                    value: _vm.formData.mobile,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "mobile", $$v)
                    },
                    expression: "formData.mobile",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-item" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "请选择状态" },
                    model: {
                      value: _vm.formData.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "state", $$v)
                      },
                      expression: "formData.state",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "全部状态", value: "" },
                    }),
                    _c("el-option", { attrs: { label: "启用", value: "1" } }),
                    _c("el-option", { attrs: { label: "禁用", value: "2" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.onHandleSearch },
              },
              [_vm._v("查询")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.buyerlistexport },
              },
              [_vm._v("导 出")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "100px",
                  align: "center",
                  label: "序号",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "logisticsName",
                  align: "center",
                  label: "集配中心",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.logistic_business_name || "-") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "team_name",
                  align: "center",
                  label: "所属小组",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "buyer_type_txt",
                  align: "center",
                  label: "类型",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fullname",
                  align: "center",
                  label: "采购员姓名",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.fullname)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "nickname", align: "center", label: "花名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.nickname)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "mobile", align: "center", label: "联系电话" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.$empty.empty(scope.row.mobile)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "jiCaicategories_name",
                  width: "300",
                  align: "center",
                  label: "负责品类",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.category_list.length > 0
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  disabled: scope.row.category_list.length <= 5,
                                  placement: "top-start",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "tooltipcontent",
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  _vm._l(
                                    scope.row.category_list,
                                    function (item) {
                                      return _c(
                                        "span",
                                        {
                                          key: item.id,
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.name) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c("el-button", { attrs: { type: "text" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "tooltiptext" },
                                    _vm._l(
                                      scope.row.category_list.length > 5
                                        ? 5
                                        : scope.row.category_list.length,
                                      function (item) {
                                        return _c(
                                          "div",
                                          {
                                            key: scope.row.category_list[
                                              item - 1
                                            ].id,
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.row.category_list[
                                                    item - 1
                                                  ].name
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _c("el-button", { attrs: { type: "text" } }, [
                              _vm._v("全品"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "supplier_count",
                  align: "center",
                  label: "对接供货商数量",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.supplier_count)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "state", align: "center", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("启用"),
                            ])
                          : _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("禁用"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.supplierHandle(
                                  scope.$index,
                                  scope.row,
                                  "edit"
                                )
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.$index, scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.state == 2 ? "启用" : "禁用")
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.dialogVisibleChange(scope.row, 1)
                              },
                            },
                          },
                          [_vm._v("绑定供应商")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.dialogVisibleChange(scope.row, 2)
                              },
                            },
                          },
                          [_vm._v("发品供应商")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.formData.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "enterprise personindex" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                center: true,
                visible: _vm.tableVisible,
                title: _vm.title,
                width: "70%",
              },
              on: {
                close: function ($event) {
                  _vm.tableVisible = false
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.tableVisible = false
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: !_vm.supplier_ids.length },
                      on: { click: _vm.onHandleBuyer },
                    },
                    [_vm._v("数据迁移")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "inputs mr10" },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入供应商名称",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formChildData.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.formChildData, "title", $$v)
                              },
                              expression: "formChildData.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.searchSupplier },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "row-box d-flex flex-column mt-20",
                  staticStyle: { height: "calc(100% - 64px)" },
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.listData,
                        height: "100%",
                        border: true,
                        "header-cell-style": {
                          color: "#333333",
                          background: "#EFF6FF",
                        },
                        "tooltip-effect": "dark",
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          width: "100px",
                          align: "center",
                          label: "序号",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          align: "center",
                          label: "供货商名称",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$empty.empty(scope.row.title)) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "stall",
                          align: "center",
                          label: "档口位置",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$empty.empty(scope.row.stall)) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fullname",
                          align: "center",
                          label: "联系人",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$empty.empty(scope.row.fullname)
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "mobile",
                          align: "center",
                          label: "联系电话",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$empty.empty(scope.row.mobile)) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          align: "center",
                          label: "对接业务采购",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return scope.row.buyer
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$empty.empty(
                                              scope.row.buyer.fullname
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandleBuyer(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("数据迁移")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pagination-position" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          type: "primary",
                          background: "",
                          "current-page": _vm.currentlistPage,
                          "page-sizes": [10, 20, 50, 100],
                          "page-size": 10,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.listtotal,
                        },
                        on: {
                          "size-change": _vm.handleBuyerChange,
                          "current-change": _vm.handleBuyerCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.buyerDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: {
                title: "数据迁移",
                visible: _vm.buyerDialogVisible,
                width: "40%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.buyerDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择数据将要迁移到的采购员姓名",
                      },
                      model: {
                        value: _vm.buyer_id,
                        callback: function ($$v) {
                          _vm.buyer_id = $$v
                        },
                        expression: "buyer_id",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.fullname + "(" + item.mobile + ")",
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.buyerDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onHandleChose },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.centerDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: {
                title: _vm.title,
                visible: _vm.centerDialogVisible,
                width: "40%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.centerDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "contents" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rulesForm,
                        "label-width": "150px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "采购类型", prop: "buyer_type" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择采购类型",
                              },
                              model: {
                                value: _vm.ruleForm.buyer_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "buyer_type", $$v)
                                },
                                expression: "ruleForm.buyer_type",
                              },
                            },
                            _vm._l(_vm.buyWayList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "集配中心",
                            prop: "logistic_business_id",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择集配中心",
                                    disabled: _vm.title != "新增采购员",
                                    clearable: "",
                                  },
                                  on: { change: _vm.onChangeLogisticDialog },
                                  model: {
                                    value: _vm.ruleForm.logistic_business_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "logistic_business_id",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.logistic_business_id",
                                  },
                                },
                                _vm._l(_vm.logisticsList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "采购员姓名", prop: "fullname" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入采购员姓名",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.ruleForm.fullname,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "fullname", $$v)
                                  },
                                  expression: "ruleForm.fullname",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话", prop: "mobile" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  maxLength: "11",
                                  placeholder: "请输入联系电话",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.ruleForm.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "mobile", $$v)
                                  },
                                  expression: "ruleForm.mobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("el-form-item", { attrs: { label: "负责品类" } }, [
                        _c(
                          "div",
                          { staticClass: "categories_name_disabled" },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: true,
                                type: "textarea",
                                autosize: "",
                                placeholder: "请输入负责品类",
                                clearable: "",
                              },
                              model: {
                                value: _vm.ruleForm.jiCaicategories_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "jiCaicategories_name",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.jiCaicategories_name",
                              },
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.allClick },
                              },
                              [_vm._v("选择")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm.title != "新增采购员"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "对接供应商数量" } },
                            [
                              _c(
                                "div",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: true,
                                      placeholder: "请输入对接供应商数量",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.supplier_count,
                                      callback: function ($$v) {
                                        _vm.supplier_count = $$v
                                      },
                                      expression: "supplier_count",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "首衡集配分享账号" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入首衡集配分享账号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.ruleForm.share_member_mobile,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "share_member_mobile",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.share_member_mobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("el-form-item", { attrs: { label: "所属小组" } }, [
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入所属小组",
                                clearable: "",
                                maxlength: "10",
                              },
                              model: {
                                value: _vm.ruleForm.team_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "team_name", $$v)
                                },
                                expression: "ruleForm.team_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "花名" } }, [
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入花名",
                                clearable: "",
                                maxlength: "10",
                              },
                              model: {
                                value: _vm.ruleForm.nickname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "nickname", $$v)
                                },
                                expression: "ruleForm.nickname",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.centerDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onHandleSubmit },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "选择品类",
            visible: _vm.productDialogVisible,
            width: "40%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.productDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "contents" },
            [
              _vm.productDialogVisible
                ? _c("el-tree", {
                    ref: "trees",
                    attrs: {
                      data: _vm.productoptions,
                      "show-checkbox": "",
                      "default-checked-keys": _vm.default_category_ids,
                      "default-expanded-keys": [0],
                      "node-key": "id",
                      props: _vm.props,
                    },
                    on: { "check-change": _vm.handleCheckChange },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onHandleSubmitProduct },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("BuyerDialog", {
        ref: "BuyerDialogs",
        on: { successDialog: _vm.successDialog },
      }),
      _c("HairBuyerDialog", {
        ref: "HairBuyerDialogs",
        on: { successDialog: _vm.successDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }